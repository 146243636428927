<template>
  <div class="card" style="margin-top: 10px">
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    </div>

    <div :class="styleForOrder(order)" style="font-weight: bold">
      Order <span style="font-size: 10px">#{{ order.objectId }}</span>
    </div>

    <div class="progressBar clear">
      <div class="progressItem">
        <div class="progressNumber">
          <div class="progress-circle green">1</div>
        </div>
        <div class="progressLabel">Order Created</div>
      </div>
      <div class="progressItem">
        <div class="progressNumber">
          <div class="progress-circle grey" :class="{ green: addressAdded }">
            2
          </div>
        </div>
        <template
          v-if="
            $parent.$parent.customLanguage.orderViewPage &&
            $parent.$parent.customLanguage.orderViewPage.headding2
          "
        >
          <div class="progressLabel">
            {{ $parent.$parent.customLanguage.orderViewPage.headding2 }}
          </div>
        </template>
        <template v-else>
          <div class="progressLabel">Delivery Address</div>
        </template>
      </div>
      <div class="progressItem">
        <div class="progressNumber">
          <div class="progress-circle grey" :class="{ green: dateAdded }">
            3
          </div>
        </div>
        <template
          v-if="
            $parent.$parent.customLanguage.orderViewPage &&
            $parent.$parent.customLanguage.orderViewPage.headding3
          "
        >
          <div class="progressLabel">
            {{ $parent.$parent.customLanguage.orderViewPage.headding3 }}
          </div>
        </template>
        <template v-else>
          <div class="progressLabel">Delivery Date</div>
        </template>
      </div>
      <div class="progressItem">
        <div class="progressNumber">
          <div class="progress-circle grey" :class="{ green: orderDone }">
            4
          </div>
        </div>
        <div class="progressLabel">Confirmation</div>
      </div>
      <div class="progressItem">
        <div class="progressNumber">
          <div class="progress-circle grey" :class="{ green: orderDone }">
            5
          </div>
        </div>
        <div class="progressLabel">Order Complete</div>
      </div>
    </div>

    <div class="container-fluid" style="padding-top: 10px">
      <template
        v-if="
          $parent.$parent.customLanguage.orderViewPage &&
          $parent.$parent.customLanguage.orderViewPage.itemsOrdered
        "
      >
        <div class="orderHeadding">
          <div class="circle green">1</div>
          <p class="heddingText">
            {{ $parent.$parent.customLanguage.orderViewPage.itemsOrdered }}
          </p>
        </div>
      </template>
      <template v-else>
        <div class="orderHeadding">
          <div class="circle green">1</div>
          <p class="heddingText">Items Ordered</p>
        </div>
      </template>

      <table class="table ordertbl">
        <thead>
          <td></td>
          <th>Item</th>
          <th>Quantity</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <img class="orderimg" v-bind:src="order.choice.image.url" />
            </td>
            <td>{{ order.choice.name }}</td>
            <td>{{ order.quantity }}</td>
          </tr>
        </tbody>
      </table>

      <div class="orderHeadding">
        <div class="circle grey" :class="{ green: addressAdded }">2</div>
        <template
          v-if="
            $parent.$parent.customLanguage.orderViewPage &&
            $parent.$parent.customLanguage.orderViewPage.deliveryDetails
          "
        >
          <p class="heddingText">
            {{ $parent.$parent.customLanguage.orderViewPage.deliveryDetails }}
          </p>
        </template>
        <template v-else>
          <template
            v-if="
              $parent.$parent.customLanguage.orderViewPage &&
              $parent.$parent.customLanguage.orderViewPage.deliveryDetails
            "
          >
            <div class="progressLabel">
              {{ $parent.$parent.customLanguage.orderViewPage.deliveryDetails }}
            </div>
          </template>
          <template v-else>
            <p class="heddingText">Delivery Details</p>
          </template>
        </template>
      </div>

      <template
        v-if="
          $parent.$parent.customLanguage.orderViewPage &&
          $parent.$parent.customLanguage.orderViewPage.deliveryContact
        "
      >
        <h5>
          {{ $parent.$parent.customLanguage.orderViewPage.deliveryContact }}
        </h5>
      </template>
      <template v-else>
        <h5>Delivery Contact</h5>
      </template>

      <p>{{ order.contact.firstName }} {{ order.contact.lastName }}</p>
      <p v-for="field in parentFields()" v-bind:key="field.objectId">
        {{ field.displayName }}: {{ order.contact[field.fieldName] }}
      </p>

      <template v-if="order.deliverToOffice === true">
        <!-- This is the view for a booked office collection -->
        <p>
          Your appointment to collect your order is confimed on
          <b>{{ officePickupDateDisplay }}</b> at
          <b>{{ officePickupTimeDisplay }}</b>
        </p>
        <template v-if="reschedule === true">
          <p>
            You can reschedule you appointment by clikcing the button below.
          </p>
          <button
            v-on:click="showReschedule = true"
            class="btn bg-primary text-white btnspace"
          >
            Reschedule
          </button>
        </template>
        <template v-if="showReschedule === true">
          <officeDelivery
            :data="officeBookingSlotsArr"
            :timeslots="formattedSlots"
          ></officeDelivery>
        </template>
      </template>
      <template v-else>
        <template v-if="enableofficebookngs !== false">
          <template v-if="deliveryType == 'home'">
            <button
              v-on:click="deliveryType = 'office'"
              class="btn bg-primary text-white btnspace"
            >
              Change to office pickup
            </button>
          </template>
          <template
            v-if="deliveryType == 'office' && this.officeBookingsOnly != true"
          >
            <button
              v-on:click="deliveryType = 'home'"
              class="btn bg-primary text-white btnspace"
            >
              Change to home delivery
            </button>
          </template>
        </template>

        <template v-if="deliveryType == 'office'">
          <officeDelivery
            :data="officeBookingSlotsArr"
            :timeslots="formattedSlots"
          ></officeDelivery>
        </template>

        <template v-if="deliveryType == 'home'">
          <template
            v-if="
              $parent.$parent.customLanguage.orderViewPage &&
              $parent.$parent.customLanguage.orderViewPage.deliveryAddress
            "
          >
            <h5>
              {{ $parent.$parent.customLanguage.orderViewPage.deliveryAddress }}
            </h5>
          </template>
          <template v-else>
            <h5>Delivery Address</h5>
          </template>
          <template v-if="$parent.$parent.customLanguage.orderViewPage">
            <div
              v-html="
                $parent.$parent.customLanguage.orderViewPage.deliveryDetails
              "
            ></div>
          </template>
          <template v-else> Enter your delivery address below </template>

          <template v-if="!addressLoading">
            <p v-if="order.deliveryAddress != null">
              {{ order.deliveryAddress.line1 }}<br />
              {{ order.deliveryAddress.line2 }}<br />
              {{ order.deliveryAddress.city }}<br />
              {{ order.deliveryAddress.postcode }}<br />
              {{ order.deliveryAddress.driverNotes }}<br />
              {{ order.deliveryAddress.contactNumber }}
            </p>
            <div
              class="w-100"
              style="display: inline-block"
              v-if="
                order.deliveryAddress != null &&
                chosenAddress == null &&
                orderIsEditable(order)
              "
            >
              <button
                v-on:click="order.deliveryAddress = null"
                class="btn bg-warning float-start deliverybtn"
              >
                Change Address
              </button>
            </div>
            <template
              v-if="chosenAddress != null && chosenAddressDisplay == true"
            >
              <div class="form-group pb-2">
                <input
                  class="form-control"
                  type="text"
                  width="100%"
                  v-model="chosenAddress.Line1"
                  placeholder="Line 1"
                  autocomplete="off"
                />
              </div>
              <div class="form-group pb-2">
                <input
                  class="form-control"
                  type="text"
                  width="100%"
                  v-model="chosenAddress.Line2"
                  placeholder="Line 2"
                  autocomplete="off"
                />
              </div>
              <div class="form-group pb-2">
                <input
                  class="form-control"
                  type="text"
                  width="100%"
                  v-model="chosenAddress.City"
                  placeholder="City"
                  autocomplete="off"
                />
              </div>
              <div class="form-group pb-4">
                <input
                  class="form-control"
                  type="text"
                  width="100%"
                  v-model="chosenAddress.PostalCode"
                  placeholder="PostalCode"
                  autocomplete="off"
                />
              </div>
              <template
                v-if="
                  $parent.$parent.customLanguage.orderViewPage &&
                  $parent.$parent.customLanguage.orderViewPage.deliveryNotes
                "
              >
                <div class="form-group pb-2">
                  <input
                    class="form-control"
                    type="text"
                    width="100%"
                    v-model="driverNotes"
                    :placeholder="
                      $parent.$parent.customLanguage.orderViewPage.deliveryNotes
                    "
                  />
                </div>
              </template>
              <template v-else>
                <div class="form-group pb-2">
                  <input
                    class="form-control"
                    type="text"
                    width="100%"
                    v-model="driverNotes"
                    placeholder="Enter any notes for the delivery driver."
                  />
                </div>
              </template>

              <div class="form-group">
                <template
                  v-if="
                    $parent.$parent.customLanguage.orderViewPage &&
                    $parent.$parent.customLanguage.orderViewPage.phoneNumberText
                  "
                >
                  <input
                    class="form-control"
                    type="text"
                    width="100%"
                    v-model="contactNumber"
                    :placeholder="
                      $parent.$parent.customLanguage.orderViewPage
                        .phoneNumberText
                    "
                  />
                </template>
                <template v-else>
                  <input
                    class="form-control"
                    type="text"
                    width="100%"
                    v-model="contactNumber"
                    placeholder="Enter your contact number for courier notifications. *"
                  />
                </template>
              </div>
            </template>
            <br />

            <div
              class="w-100"
              style="display: inline-block"
              v-if="chosenAddress != null && error != null"
            >
              <h5 class="text-danger">{{ error }}</h5>
            </div>
            <div
              class="w-100"
              style="display: inline-block"
              v-if="chosenAddress != null"
            >
              <button
                v-on:click="saveAddress()"
                class="btn bg-primary text-white float-end"
              >
                Save Address
              </button>
              <button
                v-on:click="chosenAddress = null"
                class="btn bg-warning text-white float-start"
              >
                Cancel
              </button>
            </div>
            <template
              v-if="order.deliveryAddress == null && chosenAddress == null"
            >
              <div class="input-group w-50 addressgrid">
                <input
                  v-gmaps-searchbox:myProperty="vm"
                  class="form-control"
                  placeholder="Start typing your address."
                  autocomplete="off"
                  style="width: 500px"
                />

                <div class="input-group-append addresspadding">
                  <button
                    type="button"
                    class="btn bg-primary text-white"
                    v-on:click="Address()"
                  >
                    Use Address
                  </button>
                  or
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click="createManualAddress()"
                  >
                    Add Address Manually
                  </button>
                </div>
                <li
                  class="list-group-item list-group-item-action text-primary"
                  style="cursor: pointer"
                  v-if="addressResults != null"
                  v-on:click="createManualAddress()"
                >
                  Click here to manually enter an address.
                </li>
              </div>
              <br />
              <!-- new form -->
              <div class="input-group"></div>

              <br />
            </template>
          </template>
          <div class="card card-block mx-auto" v-if="addressLoading">
            <div class="card-body text-center">
              <p class="card-text">Please wait.</p>
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
          <div class="orderHeadding">
            <div class="circle grey" :class="{ green: dateAdded }">3</div>

            <template
              v-if="
                $parent.$parent.customLanguage.orderViewPage &&
                $parent.$parent.customLanguage.orderViewPage.deliveryDay
              "
            >
              <p class="heddingText">
                {{ $parent.$parent.customLanguage.orderViewPage.deliveryDay }}
              </p>
            </template>
            <template v-else>
              <p class="heddingText">Delivery Day</p>
            </template>
          </div>

          <div
            class="w-100"
            style="display: inline-block"
            v-if="order.deliverySlot == null && orderIsEditable(order)"
          >
            <template
              v-if="
                $parent.$parent.customLanguage.orderViewPage &&
                $parent.$parent.customLanguage.orderViewPage.deliveryDaySub
              "
            >
              <p>
                {{
                  $parent.$parent.customLanguage.orderViewPage.deliveryDaySub
                }}
              </p>
            </template>
            <template v-else>
              <p>
                Click the button below to book a date for your order to be
                delivered.
              </p>
            </template>
            <button
              v-on:click="rescheduleOrder(order)"
              class="btn bg-primary text-white deliverybtn"
              :disabled="order.deliveryAddress == null"
            >
              <template
                v-if="
                  $parent.$parent.customLanguage.orderViewPage &&
                  $parent.$parent.customLanguage.orderViewPage.deliveryDayButton
                "
              >
                {{
                  $parent.$parent.customLanguage.orderViewPage.deliveryDayButton
                }}
              </template>
              <template v-else> Choose a delivery day </template>
            </button>
          </div>
          <p
            v-if="
              order.choice.requiresDelivery == true &&
              order.readyForDelivery != true &&
              orderIsEditable(order) == false
            "
          >
            Your order is not yet ready for delivery. We will email you when
            your order is ready.
          </p>
          <template v-if="order.deliverySlot != null">
            <p v-if="order.contactApproved != true && orderIsEditable(order)">
              <template
                v-if="
                  $parent.$parent.customLanguage.orderViewPage &&
                  $parent.$parent.customLanguage.orderViewPage.approvedMessage
                "
              >
                {{
                  $parent.$parent.customLanguage.orderViewPage.approvedMessage
                }}
              </template>
              <template v-else> We will deliver your device on </template>
              {{ prettyDate(order.deliverySlot.deliveryDate.iso) }}.
            </p>
            <p v-if="order.contactApproved != true && !orderIsEditable(order)">
              You've missed your delivery day.<br />
              Please make sure you've entered your delivery address and then use
              the reschedule button to choose another available delivery day.
            </p>
            <p v-if="order.contactApproved == true">
              <template
                v-if="
                  $parent.$parent.customLanguage.orderViewPage &&
                  $parent.$parent.customLanguage.orderViewPage.approvedMessage
                "
              >
                {{
                  $parent.$parent.customLanguage.orderViewPage.approvedMessage
                }}
              </template>
              <template v-else> We will deliver your device on </template>
              {{ prettyDate(order.deliverySlot.deliveryDate.iso) }}.
            </p>
            <p v-if="orderIsEditable(order)">
              If you want to reschedule your order, you must do so before
              {{ prettyDate(allowanceDate(order)) }}
            </p>
          </template>
        </template>
      </template>
      <div
        class="w-100"
        style="display: inline-block"
        v-if="order.deliverySlot != null && order.deliveryAddress != null"
      >
        <button
          v-if="orderIsEditable(order) || order.contactApproved != true"
          v-on:click="rescheduleOrder(order)"
          class="btn bg-warning deliverybtn float-start"
        >
          Reschedule
        </button>
      </div>
      <div v-if="orderIsEditable(order) && order.contactApproved != true">
        <div class="orderHeadding">
          <div class="circle grey" :class="{ green: orderApproved }">4</div>
          <p class="heddingText">Confirmation</p>
        </div>
        <p>
          Please check the details above are correct and click approve below.
        </p>

        <template>
          <button
            v-on:click="approveOrder(order)"
            class="btn bg-primary deliverybtn text-white"
            :disabled="
              order.loading == true || addressAdded != true || dateAdded != true
            "
          >
            <template
              v-if="
                $parent.$parent.customLanguage.orderViewPage &&
                $parent.$parent.customLanguage.orderViewPage.approveButton
              "
            >
              {{ $parent.$parent.customLanguage.orderViewPage.approveButton }}
            </template>
            <template v-else> Approve Delivery </template>

            <div v-if="order.loading == true">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </button>
        </template>
      </div>
      <div v-else-if="$parent.$parent.customLanguage.closeWindowText">
        <p>{{ $parent.$parent.customLanguage.closeWindowText.body }}</p>
      </div>
      <div v-else>
        <p>You can now close this window</p>
      </div>
    </div>
  </div>
</template>

<script>
import request from "request";
import axios from "axios";
const { DateTime } = require("luxon");
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import loging from "../components/loging.js";

import officeDelivery from "./OrderView/OfficeDelivery.vue";

export default {
  name: "OrderView",
  props: ["order"],
  components: {
    officeDelivery,
    Loading,
  },

  mounted: async function () {
    if (
      this.$parent.$parent.customLanguage.orderViewPage &&
      this.$parent.$parent.customLanguage.orderViewPage.phoneNumberOptioanl &&
      this.$parent.$parent.customLanguage.orderViewPage.phoneNumberOptioanl ===
        true
    ) {
      this.contactNumberOptional = true;
    }

    if (!this.order.deliveryAddress) {
      if (this.chosenAddress == null) {
        axios
          .get(
            this.$parent.$parent.config.apiV2url +
              "/contact/" +
              this.getParameterByName("l") +
              "/address",
            {
              headers: {
                "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad",
                "X-Cat24-Contact-Token": this.getParameterByName("t"),
              },
            }
          )
          .then((response) => {
            if (!response.data) {
              this.isLoading = false;
            } else {
              this.chosenAddress = {
                Line1: response.data.line1,
                Line2: response.data.line2,
                City: response.data.city,
                PostalCode: response.data.postcode,
                DeliverNotes: response.data.driverNotes,
                ContactNumber: response.data.contactNumber,
              };
              this.driverNotes = response.data.driverNotes || "";
              this.contactNumber = response.data.contactNumber || "";
            }

            console.log("RESPONSE: ", response);

            if (this.chosenAddress !== null) {
              this.isLoading = false;
            }
          })
          .catch(function (error) {
            console.log("Error: ", error);
          });
        this.isLoading = true;
      }
    }

    if (this.order.deliveryAddress != null) {
      this.addressAdded = true;
    }

    if (this.order.deliverySlot != null) {
      this.dateAdded = true;
    }

    if (this.order.deliverySlot != null && this.order.deliveryAddress != null) {
      this.confStep = true;
    }

    if (this.order.OfficeSlot != null) {
      this.addressAdded = true;
      this.dateAdded = true;
      this.orderApproved = true;
      this.orderDone = true;
    }

    if (
      this.orderIsEditable(this.order) &&
      this.order.contactApproved == true
    ) {
      this.orderApproved = true;
      this.orderDone = true;
    }

    // axios.get(this.$parent.$parent.config.apiV2url + "/customfaq",{headers: {
    //   "X-Cat24-Contact-Token" : this.getParameterByName("t"), 'X-Cat24-Contact-License': this.getParameterByName("l")
    // }} ).then((response) => {

    //   console.log("FAQ Response: ", response.body)
    //   this.customfaq = response.body

    // })

    if (typeof this.order.OfficeSlot !== "undefined" && this.order.OfficeSlot) {
      (this.officePickupDateDisplay = DateTime.fromISO(
        this.order.OfficeSlot.iso
      ).toFormat("EEEE dd MMM yyyy")),
        (this.officePickupTimeDisplay = DateTime.fromISO(
          this.order.OfficeSlot.iso
        ).toFormat("HH:mm"));

      if (
        DateTime.now().plus({ days: 7 }) >
        DateTime.fromISO(this.order.OfficeSlot.iso)
      ) {
        this.reschedule = false;
      } else {
        this.reschedule = true;
      }
    }

    if (this.officeBookingSlots == "") {
      // this stops us reloading the slots
      if (this.order.deliverToOffice === true) {
        this.getSlots(true);
      } else {
        this.getSlots(false);
      }
    }
  },
  methods: {
    fetchCurrentUser: function () {
      return this.$parent.$parent.currentUser;
    },
    rescheduleOrder: function (order) {
      this.$parent.$parent.rescheduleOrder = order;
    },
    parentFields: function () {
      return this.$parent.fields;
    },
    createManualAddress: function () {
      this.chosenAddress = {
        Line1: "",
        Line2: "",
        City: "",
        PostalCode: "",
      };
    },

    getParameterByName: function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    validateAddress: function () {
      if (this.chosenAddress.Line1.trim() == "") {
        return "Address Line 1 cannot be empty.";
      }
      if (this.chosenAddress.PostalCode.trim() == "") {
        return "Address Postal Code cannot be empty.";
      }
      if (this.contactNumberOptional !== true) {
        if (this.contactNumber.trim() == "") {
          return "Contact number cannot be empty.";
        }
      }
      return null;
    },
    Address: function () {
      this.chosenAddress = {
        Line1: "",
        Line2: "",
        City: "",
        PostalCode: "",
      };

      for (var addressitem of this.vm.myProperty.address_components) {
        switch (addressitem.types[0]) {
          case "street_number":
            this.chosenAddress.Line1 = this.chosenAddress.Line1.concat(
              " " + addressitem.long_name
            );
            break;
          case "route":
            this.chosenAddress.Line1 = this.chosenAddress.Line1.concat(
              " " + addressitem.long_name
            );
            break;
          case "postal_town":
            this.chosenAddress.City = addressitem.long_name;
            break;
          case "postal_code":
            this.chosenAddress.PostalCode =
              this.chosenAddress.PostalCode.concat(" " + addressitem.long_name);
            break;
          case "sublocality_level_1":
            this.chosenAddress.Line2 = this.chosenAddress.PostalCode.concat(
              " " + addressitem.long_name
            );
            break;
          case "locality":
            this.chosenAddress.Line2 = this.chosenAddress.PostalCode.concat(
              " " + addressitem.long_name
            );
            break;
          case "country":
            this.chosenAddress.PostalCode =
              this.chosenAddress.PostalCode.concat(" " + addressitem.long_name);
            break;
          default:
        }
      }
    },

    saveAddress: function () {
      var validationError = this.validateAddress();
      if (validationError != null) {
        this.error = validationError;
        this.addressLoading = false;
        return;
      }

      var saveWith = async function (scope) {
        var logData = scope.order;
        logData.deliveryAddress = {
          line1: scope.chosenAddress.Line1,
          line2: scope.chosenAddress.Line2,
          city: scope.chosenAddress.City,
          postcode: scope.chosenAddress.PostalCode,
          driverNotes: scope.driverNotes,
          contactNumber: scope.contactNumber,
        };

        await loging.sumo(
          {
            page: "orderView-Address",
            licence: scope.getParameterByName("l"),
            token: scope.getParameterByName("t"),
            order: logData,
          },
          scope.$parent.$parent.licenseDetails.loggingurl
        );
        scope.addressLoading = true;
        request(
          {
            url:
              scope.$parent.$parent.config.apiUrl +
              "/contact/" +
              scope.getParameterByName("l") +
              "/orders/" +
              scope.order.objectId +
              "/changeaddress",
            headers: {
              "x-cat24-contact-token": scope.getParameterByName("t"),
            },
            json: {
              line1: scope.chosenAddress.Line1,
              line2: scope.chosenAddress.Line2,
              city: scope.chosenAddress.City,
              postcode: scope.chosenAddress.PostalCode,
              driverNotes: scope.driverNotes,
              contactNumber: scope.contactNumber,
            },
            method: "POST",
          },
          function (error, response) {
            if (error != null) {
              scope.addressLoading = false;
              return;
            }

            if (response.statusCode != 200) {
              scope.error = "Failed to save your address.";
              scope.addressLoading = false;
              return;
            }

            scope.addressLoading = false;
            window.location.reload();
          }
        );
      };
      saveWith(this);
      this.chosenAddressDisplay = false;
    },
    allowanceDate: function (order) {
      if (order.deliverySlot == null) {
        return new Date();
      }
      var allowanceDate = new Date(
        Date.parse(order.deliverySlot.deliveryDate.iso)
      );
      allowanceDate.setHours(
        allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance
      );

      return allowanceDate;
    },
    orderIsEditable: function (order) {
      // if (order.contactApproved == true){
      //   return false;
      // }
      // console.log("Order: ", order);
      if (order.choice.requiresDelivery == true) {
        if (order.readyForDelivery != true) {
          return false;
        }
      }
      if (order.deliverySlot == null) {
        return true;
      }
      var now = new Date();
      var allowanceDate = new Date(
        Date.parse(order.deliverySlot.deliveryDate.iso)
      );
      allowanceDate.setHours(
        allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance
      );

      if (allowanceDate < now) {
        return false;
      }

      return true;
    },
    orderType: function (order) {
      if (order.deliverySlot == null) {
        return "Pending";
      }
      var now = new Date();
      var allowanceDate = new Date(
        Date.parse(order.deliverySlot.deliveryDate.iso)
      );
      allowanceDate.setHours(
        allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance
      );

      if (order.contactApproved == true) {
        if (allowanceDate < now) {
          return "Previous";
        }
        return "Confirmed";
      }
      if (allowanceDate < now) {
        return "Missed";
      }
      return "Pending";
    },
    prettyDate: function (iso) {
      var dateFormat = require("dateformat");
      var now = Date.parse(iso);
      return dateFormat(now, "dddd, mmmm dS, yyyy");
    },
    styleForOrder: function (order) {
      if (
        order.contactApproved == true ||
        this.order.deliverToOffice === true
      ) {
        return {
          "bg-success": true,
          "card-header": true,
          "text-white": true,
        };
      }
      return {
        "bg-warning": true,
        "card-header": true,
        "text-white": true,
      };
    },

    approveOrder: async function (order) {
      window.__vue.set(order, "loading", true);
      await loging.sumo(
        {
          page: "orderView-Approve",
          licence: this.getParameterByName("l"),
          token: this.getParameterByName("t"),
          order: order,
        },
        this.$parent.$parent.licenseDetails.loggingurl
      );
      var fetchWith = async function (scope) {
        request(
          {
            url:
              scope.$parent.$parent.config.apiUrl +
              "/contact/" +
              scope.getParameterByName("l") +
              "/orders/" +
              order.objectId +
              "/approve",
            headers: {
              "x-cat24-contact-token": scope.getParameterByName("t"),
            },
            method: "GET",
          },
          function () {
            window.location.reload();
          }
        );
      };
      fetchWith(this);
    },
    getSlots: async function (silent) {
      if (silent !== true) {
        this.isLoading = true;
      }
      await axios
        .get(
          this.$parent.$parent.config.apiV2url +
            "/bookingss/office/" +
            this.getParameterByName("l"),
          {
            headers: {
              "x-cat24-contact-token": this.getParameterByName("t"),
              "X-Cat24-Contact-License": this.getParameterByName("l"),
              "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad",
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          if (response.data.enabled === false) {
            this.enableofficebookngs = false;
          } else {
            this.enableofficebookngs = true;

            console.log(response.data);
            console.log("---");
            if ("officeBookingsOnly" in response.data) {
              this.officeBookingsOnly = true;
              this.deliveryType = "office";
            }

            for (var booking in response.data.bookingSlots) {
              var count = 0;
              for (var slot in response.data.bookingSlots[booking]) {
                if (response.data.bookingSlots[booking][slot] != "") {
                  count = count + 1;
                }
              }
              if (
                count == Object.keys(response.data.bookingSlots[booking]).length
              ) {
                delete response.data.bookingSlots[booking];
              } else {
                var bookingdisplay =
                  DateTime.fromISO(booking).toFormat("EEEE dd MMM yyyy");
                var bookingTimedisplay =
                  DateTime.fromISO(booking).toFormat("HH:mm");

                if (!this.formattedSlots[bookingdisplay.replace(/ /g, "")]) {
                  this.formattedSlots[bookingdisplay.replace(/ /g, "")] = [];
                  this.officeBookingSlotsArr.push({ appTime: bookingdisplay });
                }
                this.formattedSlots[bookingdisplay.replace(/ /g, "")].push({
                  time: bookingTimedisplay,
                });
              }
            }
            this.officeBookingSlots = response.data;
          }
        });
    },
  },
  data: function () {
    return {
      loading: true,
      addressSearch: "",
      addressResults: null,
      addressLoading: false,
      driverNotes: "",
      contactNumber: "",
      chosenAddress: null,
      chosenAddressDisplay: true,
      customText: "",
      error: "",
      vm: {},
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      isLoading: false,
      fullPage: true,
      addresschosen: "0",
      officeBookingSlots: "",
      officeBookingSlotsArr: [],
      officePickupDateDisplay: "",
      officePickupTimeDisplay: "",
      formattedSlots: {},
      reschedule: false,
      enableofficebookngs: false,
      showReschedule: false,
      deliveryType: "home",
      addressAdded: false,
      dateAdded: false,
      confStep: false,
      orderDone: false,
      orderApproved: false,
      customfaq: [],
      contactNumberOptional: false,
      officeBookingsOnly: false,
      myProperty: {
        postal_code: "",
        website: "",
        formatted_address: "",
        geometry: "",
      },

      GoogleData: {
        name: "",
        formatted_address: "",
        geometry: "",
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btnspace {
  margin-top: 10px;
  margin-bottom: 10px;
}

.orderline {
  padding-top: -10px;
}

.circle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 5px;
  color: #666;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-weight: bold;
  float: left;
}

.green {
  background-color: #27ae5f !important;
  border: 2px solid #27ae5f !important;
}

.red {
  background-color: #e64c3b !important;
  border: 2px solid #e64c3b !important;
}

.grey {
  background-color: #727272;
  border: 2px solid #727272;
}

.orderHeadding {
  padding-bottom: 10px;
}

.heddingText {
  margin-left: 50px;
  font-size: 25px;
}

.orderimg {
  height: 40px;
}

.ordertbl td {
  height: 70px;
  vertical-align: middle;
}

.deliverybtn {
  margin-bottom: 10px;
}

td img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#app
  > div:nth-child(2)
  > div
  > div.col-md-8.my-auto
  > div
  > div.card-body.text-start
  > div
  > div.progress
  > div
  > span:nth-child(5) {
  display: none !important;
  font-size: 50px;
}

.progress-label {
  clear: left;
  overflow: auto;
}

.progress-circle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 5px;

  color: #666;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-weight: bold;
  float: left;
}

.progressBar {
  background: #fff;
  font-size: 15px;
  height: 50px;
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
  margin: auto;

  padding-top: 20px;
  margin-bottom: 20px;
}

.progressNumber {
  float: top;
  align-content: center;
}

.progressLabel {
  float: left;
  margin-left: 5px;
  vertical-align: bottom;
  height: 50px;
  line-height: 35px;
}

.progressItem {
  margin-right: 20px;
  align-content: center;
  display: flex;
}

.clear:after {
  clear: both;
  display: table;
  content: "";
}

.addressgrid {
  display: grid;
}

.addresspadding {
  padding-top: 20px;
}
</style>
